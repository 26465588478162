/* eslint-disable react-hooks/exhaustive-deps */
import dynamic from 'next/dynamic'

import { log } from '@/services/Log'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { useMobileDetect } from '@/hooks/useMobileDetect'
import type { LoadedRecommendationBlock } from '@/types/RecommendationBlocks'

const ListrakRecommendation = dynamic(import('@/components/Pdp/ListrakRecommendation'))
const Col = dynamic(import('@csc/dls/Col'))

const isRecommendationLoadedWithProducts = (block: LoadedRecommendationBlock) => (
  block.products.length > 0
)

export const MaybeListrakRec: React.FC<{
  recommendationBlocks: readonly LoadedRecommendationBlock[],
  isFreeRugDoorstepShippingOn: boolean,
  addToCartFunction?: (d: { sku: string }) => void,
}> = ({
  recommendationBlocks,
  isFreeRugDoorstepShippingOn,
  addToCartFunction,
}) => {
  const isMobile = useMobileDetect()
  const listrakFeatureEnabled = isFeatureEnabled('ltkPdpRecEnabled')
  if (!listrakFeatureEnabled) {
    log('Listrak feature not enabled. \'ltkPdpRecEnabled\'', { listrakFeatureEnabled })
    return null
  }
  return (
    <>
      {recommendationBlocks.filter(isRecommendationLoadedWithProducts).map((block, index) => (
        <Col
          key={block.mechandiseBlockId}
          span={{ sm: 12, lg: 12 }}
          className="w-full"
        >
          <ListrakRecommendation
            addToCartFunction={addToCartFunction}
            isFreeRugDoorstepShippingOn={isFreeRugDoorstepShippingOn}
            isMobile={isMobile}
            block={block}
            index={index}
          />
        </Col>
      ))}
    </>
  )
}

export default MaybeListrakRec
